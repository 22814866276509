@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;600;800&family=Lexend:wght@300&display=swap');

* {
    font-family: 'Inter';
}

a {
    cursor: none;
}

#redirect {
    width: 100%;
    height: 100%;
    background-color: red;
}

.Container {
    cursor: none !important;
    background-color: rgb(0, 0, 0);
}

.cursor {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 80px;
    height: 80px;
    /* permet de center le default cursor au millieu du custom */
    margin-top: -40px;
    margin-left: -40px;
    z-index: 1;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    pointer-events: none;
    transition: transform 300ms ease-in-out;
}

.cursor.hover {
    transform: scale(0.75);
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 20, 0.5);
    border-radius: 50%;
    box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 1);
}

.cursor-hover {
    animation: spin 10s infinite;
    transition: all 0.3s ease-in-out;
}

.cursor-hover.hide {
    opacity: 0;
    animation: none;
}

.earth {
    width: 200px;
    height: 200px;
    background-image: url('./img/earth.png');
    background-color: rgb(62, 62, 62);
    background-size: cover;
    border: 2px solid #000;
    border-radius: 50%;
    animation: move 200s linear 0s infinite;
    box-shadow: 0 0 25px RGBA(255, 255, 255, 0.10),
        -8px -8px 15px #000 inset,
        2px 2px 25px #000 inset,
        -45px -45px 25px RGBA(0, 0, 0, 0.5) inset,
        25px 25px 45px RGBA(0, 0, 0, 0.45) inset;
    transform: rotateX(6deg) rotateY(6deg) rotateZ(0deg);
}

.experiences,
.project-details {
    list-style: none;
}

.fade-in {
    top: 0;
    bottom: 0;
    width: 100vw;
    background-color: black;
    z-index: 999999;
    animation: fadeIn 1s linear;
}

.footer {
    position: fixed;
    font-size: small;
    color: rgb(148, 148, 148);
    bottom: 5px;
    left: 10px;
}

.hide {
    opacity: 0;
}

.navbar {
    z-index: 0;
    transition: all 400ms ease-in-out;
}

.navbar.hidden {
    transform: translateY(-100%);
}

.navbar span {
    position: relative;
    border: 1px solid rgba(200, 197, 197, 0.5);
    padding: 5px 40px;
    font-weight: 600;
    transition: color 300ms ease-in-out;
}

.navbar span:after {
    position: absolute;
    left: 0%;
    top: 0%;
    content: '';
    height: 100%;
    transition: all 200ms linear;
    width: 100%;
    border-bottom: 3px solid #fff;
    transform: scaleX(0);
}

.navbar span:hover {
    color: #fff !important;
}

.navbar span.active:after {
    transform: scale(1);
}


.navbar span.active {
    transform: scaleX(1);
    transition: all 300ms ease-in-out;
}

.icon {
    padding: 2px;
    width: 65px;
    height: 65px;
    filter: invert(1);
}

.icon-container {
    border: 2px solid #fff;
    transition: all 0.25s ease-in-out;
}

.icon-container.hover {
    border-width: 1;
    transform: scale(1.2);
    box-shadow: 0px 0px 10px 4px rgb(247, 247, 247);
}

.img-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    transition: all 300ms ease-in-out;
}

.img-modal.hidden {
    z-index: -1;
    opacity: 0;
}

.img-modal * {
    position: absolute;
    height: 500px;
    transform: translateY(100%);
    left: 25%;
}

.pop-up .icon {
    width: 60px;
    height: 60px;
    border: none;
}

.outer-orbit,
.middle-orbit,
.inner-orbit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 215px;
    height: 215px;
    border: 1px solid rgb(129, 129, 129);
    border-radius: 50%;
    animation: spin 200s infinite;
}

.map {
    width: 2048px;
    height: 1536px;
    background-image: url('./img/map.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: appear 200ms linear;
    transform: translateY(-30%);
}

.space .section {
    overflow-y: hidden !important;

}

.middle-orbit {
    width: 260px;
    height: 260px;
    border: 2px dotted rgb(129, 129, 129);
}


.outer-orbit {
    width: 280px;
    height: 280px;
    border: 2px dashed rgb(129, 129, 129);
}

.page {
    overflow-y: hidden !important;
    transition: opacity 500ms ease-in-out;
}

.page.hidden {
    height: 0;
    opacity: 0;
}

.page.hidden * {
    display: none;
}

.pfp {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid rgb(129, 129, 129);
}

.planet {
    position: absolute;
    width: fit-content;
    height: fit-content;
}

.planet-title {
    letter-spacing: 10px;
    word-wrap: break-word;
}

.pop-up {
    position: fixed;
    max-width: 300px;
    background-color: rgba(1, 5, 8, 0.7);
    border-top: 5px solid rgb(255, 140, 0);
    transition: opacity 0.2s ease-in-out;
    z-index: 2;
}

.pop-up h6 {
    font-weight: 600;
}

.pop-up.hide {
    opacity: 0;
}

.about {
    display: flex;
    position: relative;
    height: 180vh;
    background: radial-gradient(#292b2b, #010508);
    font-weight: 600;
}

.about h1 {
    font-weight: 800;
}

.about-container {
    position: absolute;
    top: 7%;
}

.project-details li::before {
    content: "» ";
}

.skills {
    max-width: 500px;
    border: 1px solid #fff;
}

.skills div:last-of-type {
    margin: 0 !important;
}

.skills h3 {
    position: relative;
    width: fit-content;
}

.skills h3:after {
    position: absolute;
    left: 0%;
    top: -50%;
    content: '';
    height: 100%;
    transition: all 200ms linear;
    width: 100%;
    border-bottom: 3px solid #fff;
    transform: scaleX(1);
    transition: all 200ms ease-in-out;
}

.skills div:hover h3:after {
    transform: scaleX(0);
}

.sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    margin-left: 50px;
    margin-bottom: 150px;
    margin-right: -100px;
    z-index: 0;
    font-weight: 600;
}

.sidebar h2 {
    border-top: 1px solid #c8c5c5;
    border-bottom: 1px solid #c8c5c5;
    letter-spacing: 1px;
    font-weight: 800;
}

.space {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;
    background: radial-gradient(#29393b, #010508);
}

.space-around {
    margin: 3px;
    padding: 2px;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        z-index: 0;
    }
}

@keyframes move {
    0% {
        background-position: 0 0
    }

    100% {
        background-position: 315px 0
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}


@media screen and (min-height: 1081px) {
    .map {
        transform: translateY(-10%);
    }
}

@media screen and (max-width: 1052px) {
    .about-container {
        flex-direction: column;
        align-items: center;
    }

    .pfp-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width: 531px) {
    .skills {
        max-width: 300px;
    }

    .pfp-container h1 {
        text-align: center;
        ;
    }

}